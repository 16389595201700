<template>
  <div v-if="this.order === null" id="modal_popup_loading-orders" class="modal-popup">
    <div class="modal-popup-content">
      <img src="@/assets/images/loading.gif" />
      <p v-html="$t('loadingPopupMessage')"></p>
    </div>
  </div>
  <div v-else>
    <div class="order-title">
      <h1>{{ $t("orderDetailsTitle", { orderId: this.order.number }) }}</h1>
      <p v-html="$t('orderDescription') +
        ' ' +
        this.getDisplayDate(order.date) +
        ' - ' +
        order.total_amount_value +
        ' ' +
        $t('currency')
        "></p>
    </div>
    <div class="options">
      <div class="left">
        <span class="status-background" :class="selectColorStatus(order.status)">{{ $t("orderStatus_" + order.status)
        }}</span>
        <br />
        <br />
        <a class="action-button" v-if="order.status === 'P'" @click="selectOrderAction(order, 'cancel')">
          <span>{{ $t("cancelOrderButtonLabel") }}</span>
        </a>
        &nbsp;
        <!-- <a class="action-button" v-if="order.status === 'P' && order.type === 'REPL'" @click="selectOrderAction(order, 'urgent')">
          <span>{{ $t("urgentOrderButtonLabel") }}</span>
        </a>
        &nbsp; -->
        <a v-if="order.type === 'PAPER' && order.status === 'E'" class="action-button"
          @click="selectOrderAction(order, 'awb')">
          <span>{{ $t("trackOrderButtonLabel") }}</span>
        </a>
      </div>
      <div class="right">
        <a v-if="
          (order.status === 'PRP' ||
          order.status === 'V' ||
          order.status === 'PV' ||
          order.status === 'PR' ||
          order.status === 'L' ||
          order.status === 'E') &&
          order.type !== 'REPL' &&
          order.type !== 'PIN' &&
          order.type !== 'CLO' &&
          customer.can_use_csv
          " @click="downloadOrderFile('113')" class="notification-action">
          <img src="@/assets/images/download-alb.svg" alt />
          <span>{{
            $t("reportButtonLabelCsv")
          }}</span>
        </a>
        <a v-if="(order.status === 'V' ||
              order.status === 'L' ||
              order.status === 'E') &&
            order.type !== 'REPL' &&
            order.type !== 'PIN' &&
            order.type !== 'CLO' &&
            order.type !== 'NEWCR'
            " @click="downloadOrderFile('106')" class="notification-action">
          <img src="@/assets/images/download-alb.svg" alt />
          <span>{{
            $t(
              order.type === "NEW"
                ? "reportButtonLabelPaper"
                : "reportCardsButtonLabelPaper"
            )
          }}</span>
        </a>
        <a v-if="(order.status === 'V' ||
                      order.status === 'PV' ||
                      order.status === 'PR' ||
              order.status === 'L' ||
              order.status === 'E') &&
            order.type !== 'REPL' &&
            order.type !== 'PIN' &&
            order.type !== 'CLO' &&
            order.type !== 'NEW'
            " @click="downloadOrderFile('102')" class="notification-action">
          <img src="@/assets/images/download-alb.svg" alt />
          <span>{{
            $t(
              order.type === "NEWCR"
                ? "reportButtonLabelPaper"
                : "reportTopUpButtonLabelPaper"
            )
          }}</span>
        </a>
        <a v-if="(order.status === 'V' ||
              order.status === 'L' ||
              order.status === 'E') &&
            order.type !== 'REPL' &&
            order.type !== 'CLO' &&
            order.type !== 'PIN' &&
            order.type !== 'NEW'
            " @click="downloadOrderFile('103')" class="notification-action">
          <img src="@/assets/images/download-alb.svg" alt />
          <span>{{
            $t(
              order.type === "NEWCR"
                ? "reportButtonLabelXls"
                : "reportTopUpButtonLabelXls"
            )
          }}</span>
        </a>
        <a @click="downloadOrderFile('')" class="notification-action" v-if="order.type !== 'REPL' &&
            order.type != 'PIN' &&
            order.type != 'CLO' &&
            (order.number > 0 ||
              !this.isInvoiceTypeProform ||
              this.customer.state_owned) &&
            order.total_amount_value > 0 &&
            order.status !== 'A' &&
            order.status !== 'S' &&
            order.status !== 'Z'
            ">
          <img src="@/assets/images/download-alb.svg" alt />
          <span>
            {{
              this.isInvoiceTypeProform
              ? $t("proformaButtonLabel")
              : $t("invoiceButtonLabel")
            }}
          </span>
        </a>
      </div>
    </div>

    <div class="container-alb moverflow">
      <table cellpadding="10" cellspacing="0" width="95%" align="center">
        <tr class="trhover">
          <td valign="middle">
            <strong>{{ $t("orderDetailsProductLabel") }}</strong>
          </td>
          <td valign="middle">
            <span>
              <img :src="require('@/assets/images/bullet_' + order.product + '.svg')
                " />
              &nbsp;&nbsp;{{ getProductNameMethod(order.product) }}
            </span>
          </td>
        </tr>
        <tr class="trhover gri-cell">
          <td valign="middle">
            <strong>{{ $t("orderDetailsNominalValueLabel") }}</strong>
          </td>
          <td valign="middle">
            <div v-html="order.total_nominal_value + ' ' + $t('currency')" />
          </td>
        </tr>
        <tr class="trhover">
          <td valign="middle">
            <strong>{{ $t("orderDetailsTotalValueLabel") }}</strong>
          </td>
          <td valign="middle">
            <div v-html="order.total_amount_value + ' ' + $t('currency')" />
          </td>
        </tr>
        <tr v-if="this.orderType !== 'NEWCR'" class="trhover gri-cell">
          <td valign="middle">
            <strong>{{ $t("orderDetailsDeliveryLabel") }}</strong>
          </td>
          <td valign="middle">
            <table v-if="this.beneficiaries">
              <template v-for="(address, index) in orderAddresses">
                <tr :key="index">
                  <td>{{ getAddressDisplayName(address) }}</td>
                </tr>
              </template>
            </table>
          </td>
        </tr>
        <tr class="trhover" v-if="this.order.type === 'NEW'">
          <td valign="middle">
            <strong>{{ $t("orderDetailsNoCardsLabel") }}</strong>
          </td>
          <td valign="middle">{{ order.total_cards }}</td>
        </tr>
        <tr v-else :class="this.order.type === 'NEW' && 'trhover' || this.order.type !== 'NEW' && 'trhover gri-cell'">
          <td valign="middle">
            <strong>{{ $t("orderDetailsNoTicketsLabel") }}</strong>
          </td>
          <td valign="middle">{{ order.total_vouchers }}</td>
        </tr>
        <tr class="trhover" v-if="this.order.type === 'PAPER'">
          <td valign="middle">
            <strong>{{ $t("messageTicket") }}</strong>
          </td>
          <td valign="middle">{{ beneficiaries[0].embossing }}</td>
        </tr>
        <tr class="trhover" v-if="this.order.type === 'NEWCR' || this.order.type === 'MIX'">
          <td valign="middle">
            <strong>{{ $t("topUpDateLabel") }}</strong>
          </td>
          <td valign="middle">{{ order.charge_date ? this.getDisplayDate(order.charge_date) : $t("messageNotCharged") }}
          </td>
        </tr>
      </table>
    </div>

    <div class="container-alb" v-if="order.status !== 'Z'">
      <div class="header-table">
        <div class="left">
          <h3>{{ $t("beneficiariesName") }}</h3>
          <img  class="download" @click="downloadExcelOrderFile" src="@/assets/images/download.svg" />
        </div>
        <div class="right">
          <form @submit.prevent="
                                            {
            return false;
          }
            ">
            <input type="text" v-model="search" :placeholder="$t('beneficiariesFilterKeyword')"
              class="formedituser search-input" @keyup="updateInputCharacters()"
              v-on:keyup.enter="getCustomerOrderDetails()" />
          </form>
        </div>
      </div>
      <table id="test" cellpadding="10" cellspacing="0" width="95%" align="center">
        <tr v-if="this.beneficiaries === null">
          <td colspan="12">
            <div class="loading">
              <img src="@/assets/images/loading.gif" />
            </div>
          </td>
        </tr>
        <tr v-if="this.beneficiaries !== null && this.beneficiaries.length > 0">
          <th v-if="this.beneficiaries[0].cnp !== 0 && this.orderType !== 'PAPER'" @click="() => (sortBy = 'last_name')"
            valign="middle">
            <strong>{{ $t("beneficiariesTableName") }}</strong>&nbsp;
          </th>
          <th v-if="this.beneficiaries[0].cnp !== 0 && this.orderType !== 'PAPER'" @click="() => (sortBy = 'first_name')"
            valign="middle">
            <strong>{{ $t("beneficiariesTableSurname") }}</strong>&nbsp;
          </th>
          <th v-if="this.beneficiaries[0].cnp !== 0 && this.orderType === 'PAPER'" @click="() => (sortBy = 'first_name')"
            valign="middle">
            <strong>{{ $t("beneficiariesTableNameAndSurname") }}</strong>&nbsp;
          </th>
          <th v-if="this.beneficiaries[0].cnp !== 0" @click="() => (sortBy = 'cnp')" valign="middle" class="priority-1">
            <strong>{{ $t("beneficiariesTableCNP") }}</strong>&nbsp;
          </th>
          <th v-if="this.beneficiaries[0].cnp === 0" valign="middle">
            <strong>{{ $t("orderNumberCarnetsPlaceholder") }}</strong>&nbsp;
          </th>
          <th v-if="this.orderType === 'NEWCR' ||
            this.orderType === 'PAPER' ||
            this.orderType === 'MIX'
            " valign="middle" class="priority-2">
            <strong>{{ $t("beneficiariesTableNoOfTickets") }}</strong>&nbsp;
          </th>
          <th v-if="this.orderType === 'NEWCR' ||
            this.orderType === 'PAPER' ||
            this.orderType === 'MIX'
            " valign="middle" class="priority-3">
            <strong>{{ $t("beneficiariesTableTicketValue") }}</strong>&nbsp;
          </th>
          <th v-if="this.orderType === 'NEWCR' ||
            this.orderType === 'PAPER' ||
            this.orderType === 'MIX'
            " valign="middle" class="priority-4">
            <strong>{{ $t("beneficiariesTableTotalValue") }}</strong>
          </th>
          <th v-if="this.orderType === 'NEW' || orderType === 'MIX' || orderType === 'REPL'" valign="middle" class="priority-4">
            <strong>{{ $t("beneficiariesTableCardType") }}</strong>
          </th>
          <th v-if="this.orderType !== 'NEWCR'" valign="middle" class="priority-5">
            <strong>{{ $t("beneficiariesTableAddress") }}</strong>&nbsp;
          </th>
          <th v-if="this.orderType === 'NEW' || orderType === 'MIX'" valign="middle" class="priority-4">
            <strong>{{ $t("beneficiariesTableMobile") }}</strong>
          </th>
          <th v-if="this.orderType === 'NEW' || orderType === 'MIX'" valign="middle" class="priority-5">
            <strong>{{ $t("beneficiariesTableEmail") }}</strong>
          </th>
        </tr>
        <tr v-if="this.beneficiaries !== null && this.beneficiaries.length === 0">
          <td colspan="10">{{ $t("noBeneficiariesInfoMessage") }}</td>
        </tr>
        <template v-for="(beneficiary, index) in beneficiaries">
          <tr :key="index" class="trhover parent" :class="index % 2 === 0 && 'gri-cell'"
            @click="selectBeneficiaryMobile(index)">
            <td valign="middle">
              <a class="detailsOrderMobile priority-show-5 display-inline-block">
                <font-awesome-icon icon="minus-circle" v-if="detailsMobile && beneficiary === beneficiarySelectedMobile
                  " />
                <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
              </a>
              <span v-if="beneficiary.cnp !== 0">
                {{ beneficiary.last_name }}
              </span>
              <span v-if="beneficiary.cnp === 0">
                {{ beneficiary.booklets }}
              </span>
            </td>
            <td v-if="beneficiary.cnp !== 0 && orderType !== 'PAPER'" valign="middle">
              {{ beneficiary.first_name }}
            </td>
            <td v-if="beneficiary.cnp !== 0" valign="middle" class="priority-1">
              {{ beneficiary.cnp }}
            </td>
            <td v-if="orderType === 'NEWCR' ||
              orderType === 'PAPER' ||
              orderType === 'MIX'
              " valign="middle" class="priority-2">
              {{ beneficiary.vouchers }}
            </td>
            <td v-if="orderType === 'NEWCR' ||
              orderType === 'PAPER' ||
              orderType === 'MIX'
              " valign="middle" class="priority-3">
              <div v-html="beneficiary.nominal_value + ' ' + $t('currency')" />
            </td>
            <td v-if="orderType === 'NEWCR' ||
              orderType === 'PAPER' ||
              orderType === 'MIX'
              " valign="middle" class="priority-4">
              <div v-html="beneficiary.total_nominal_value + ' ' + $t('currency')" />
            </td>
            <td v-if="orderType === 'NEW' || orderType === 'MIX'  || orderType === 'REPL'" valign="middle" class="priority-4">
              <span>{{ beneficiary.card_virtual ? $t("beneficiariesTableCardTypeVirtual") :  $t("beneficiariesTableCardTypePhysical")  }}</span>
            </td>
            <td v-if="orderType !== 'NEWCR'" valign="middle" class="priority-5">
              <span v-if="beneficiary.cards === 1">
                {{ getAddressDisplayName(beneficiary.address) }}
              </span>
            </td>
            <td v-if="orderType === 'NEW' || orderType === 'MIX'" valign="middle" class="priority-4">
              <span v-if="beneficiary.cards === 1">
                {{ beneficiary.phone }}
              </span>
            </td>
            <td v-if="orderType === 'NEW' || orderType === 'MIX'" valign="middle" class="priority-5">
              <span v-if="beneficiary.cards === 1">
                {{ beneficiary.email }}
              </span>
            </td>
          </tr>
          <tr :key="index + beneficiaries.length" class="child" :class="index % 2 === 0 && 'gri-cell'"
            v-show="detailsMobile && beneficiary === beneficiarySelectedMobile">
            <td colspan="12">
              <p v-if="beneficiary.cnp !== 0" class="priority-show-1">
                <strong>{{ $t("beneficiariesTableCNP") }} &nbsp;&nbsp;</strong>
                <span>{{ beneficiary.cnp }}</span>
              </p>
              <p v-if="orderType === 'NEW' || orderType === 'MIX' || orderType === 'REPL'" class="priority-show-4">
                <strong>{{ $t("beneficiariesTableCardType") }} &nbsp;&nbsp;</strong>
                <span>{{ beneficiary.card_virtual ? $t("beneficiariesTableCardTypeVirtual") :  $t("beneficiariesTableCardTypePhysical")  }}</span>
              </p>
              <p v-if="orderType === 'NEWCR' ||
                orderType === 'PAPER' ||
                orderType === 'MIX'
                " class="priority-show-2">
                <strong>
                  {{ $t("beneficiariesTableNoOfTickets") }}
                  &nbsp;&nbsp;
                </strong>
                <span>{{ beneficiary.vouchers }}</span>
              </p>
              <p v-if="orderType === 'NEWCR' ||
                orderType === 'PAPER' ||
                orderType === 'MIX'
                " class="priority-show-3">
                <strong>
                  {{ $t("beneficiariesTableTicketValue") }}
                  &nbsp;&nbsp;
                </strong>
                <span v-html="beneficiary.nominal_value + ' ' + $t('currency')"></span>
              </p>
              <p v-if="orderType === 'NEWCR' ||
                orderType === 'PAPER' ||
                orderType === 'MIX'
                " class="priority-show-4">
                <strong>{{ $t("beneficiariesTableTotalValue") }} &nbsp;&nbsp;</strong>
                <span v-html="beneficiary.total_nominal_value + ' ' + $t('currency')
                  "></span>
              </p>
              <p v-if="orderType === 'NEW' || orderType === 'MIX'" class="priority-show-4">
                <strong>{{ $t("beneficiariesTableMobile") }} &nbsp;&nbsp;</strong>
                <span>{{ beneficiary.phone }}</span>
              </p>
              <p v-if="orderType === 'NEW' || orderType === 'MIX'" class="priority-show-5">
                <strong>{{ $t("beneficiariesTableEmail") }} &nbsp;&nbsp;</strong>
                <span>{{ beneficiary.email }}</span>
              </p>
              <p v-if="orderType !== 'NEWCR'" class="priority-show-5">
                <strong>{{ $t("beneficiariesTableAddress") }} &nbsp;&nbsp;</strong>
                <span>{{ getAddressDisplayName(orderAddresses[0]) }}</span>
              </p>
            </td>
          </tr>
        </template>
      </table>

      <div class="pagination-wrapper border-box" v-if="this.beneficiariesCount > 0">
        <b-pagination v-model="currentPage" :total-rows="beneficiariesCount" :per-page="perPage"
          aria-controls="orders-table" size="sm"></b-pagination>
      </div>
    </div>

    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_info', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="this.orderError">
          <p v-if="this.orderError.code === 'HEADER'" v-html="$t('orderError_500')"></p>
          <p v-else-if="this.orderError.code === 'DETAILS'" v-html="$t('orderError_500')"></p>
          <p v-else-if="this.orderError.code === 'DOWNLOAD'" v-html="$t('noDocumentMessage')"></p>
          <p v-else-if="this.orderError.code === 'DELETE'" v-html="$t('orderError_DELETE')"></p>
        </div>
        <div v-else>
          <p v-if="this.deleteSuccess" v-html="$t('orderDeleteSuccess')"></p>
        </div>
      </div>
    </div>
    <div id="modal_popup_confirmOrderDelete" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_confirmOrderDelete', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p v-html="$t('confirmOrderDelete')"></p>
        <div class="buttons">
          <a href="#" class="yesButton" @click="cancelOrder(orderForDelete)">
            {{ $t("notificationsChooseOptionYes") }}
          </a>
          <a href="#" class="noButton" @click="showModalPopup('modal_popup_confirmOrderDelete', false)">{{
            $t("notificationsChooseOptionNo") }}</a>
        </div>
      </div>
    </div>
    <div id="modal_popup_track" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_track', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <table cellpadding="10" cellspacing="0" width="95%" class="hover-table invoices-table text-left" align="center">
          <thead>
            <tr>
              <td valign="middle">
                <strong>{{ $t("beneficiariesFilterDeliveryAddress") }}</strong>
              </td>
              <td valign="middle" class="priority-2">
                <strong>{{ $t("courierName") }}</strong>
              </td>
              <td valign="middle" class="priority-3">
                <strong>{{ $t("trackOrderButtonLabel") }}</strong>
              </td>
              <td valign="middle">
                <strong>{{ $t("beneficiariesTableSeeMore") }}</strong>
              </td>
            </tr>
          </thead>
          <tbody v-if="this.orderAWBLoaded">
            <template v-for="(address, index) in orderForTrack.awb.addresses">
              <tr :key="index" class="trhover parent" :class="index % 2 === 0 && 'gri-cell'">
                <td valign="middle">
                  <a @click="selectAddressAWBMobile(index)"
                    class="detailsOrderMobile priority-show-3 display-inline-block">
                    <font-awesome-icon icon="minus-circle" v-if="detailsMobile && address === selectedAWB" />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                  <span>{{ address.locality + ", " + address.county }}</span>
                </td>
                <td valign="middle" class="priority-2">
                  <span>{{ address.awb.courier_name }}</span>
                </td>
                <td valign="middle" class="priority-3">
                  <span>{{ address.awb.awb_number }}</span>
                </td>
                <td valign="middle">
                  <a v-if="!trackHasStatus" class="action-button" @click="getAWBStatus(address.awb)">
                    <span>{{ $t("details") }}</span>
                  </a>
                  <div v-else>
                    <span v-if="statusAWB.awb_message">{{
                      statusAWB.awb_message
                    }}</span>
                    <span v-else>{{ $t("statusAWBUnavailable") }}</span>
                  </div>
                </td>
              </tr>
              <tr :key="index + orderForTrack.awb.addresses.length" class="child" :class="index % 2 === 0 && 'gri-cell'"
                v-show="detailsMobile && address === selectedAWB">
                <td colspan="12">
                  <p class="priority-show-2">
                    <strong>{{ $t("courierName") }} : &nbsp;&nbsp;</strong>
                    <span>{{ address.awb.courier_name }}</span>
                  </p>
                  <p class="priority-show-3">
                    <strong>{{ $t("trackOrderButtonLabel") }} : &nbsp;&nbsp;</strong>
                    <span>{{ address.awb.awb_number }}</span>
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="12" valign="middle">
                <p>{{ $t("noAWB") }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div id="modal_popup_activateInvoices" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_activateInvoices')" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div class="invoice-scroll">
          <NoElectronicInvoiceTemplate />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import utils from "@/plugins/utils.js";
import moment from "moment";
import httpServiceCustomer from "@/http/http-customer.js";
import httpServiceCompany from "@/http/http-user.js";
import { saveAs } from "file-saver";
import { BPagination } from "bootstrap-vue";
import NoElectronicInvoiceTemplate from "@/assets/components/NoElectronicInvoiceTemplate.vue";

export default {
  props: {
    orderId: String,
    customer: Object,
    orderType: String,
    msg: String
  },
  components: {
    NoElectronicInvoiceTemplate,
    "b-pagination": BPagination
  },
  data() {
    return {
      beneficiaries: null,
      order: null,
      orderError: null,
      openDetails: false,
      search: "",
      sortBy: "first_name",
      reverse: false,
      detailsMobile: false,
      beneficiarySelectedMobile: null,
      deliveryAddresses: null,
      orderForDelete: null,
      orderForTrack: null,
      orderAWBLoaded: null,
      trackHasStatus: false,
      selectedAWB: null,
      statusAWB: null,
      deleteSuccess: false,
      perPage: 10,
      currentPage: 1,
      beneficiariesCount: 0
    };
  },
  methods: {
    getCustomerOrderHeader() {
      httpServiceCustomer.customerService
        .getOrderHeader(
          this.orderId,
          this.customer.company_id,
          Cookies.get("token"),
          this.orderType
        )
        .then(function (response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.order = response.data;
            this.orderType = this.order.type;
            this.orderError = null;
            this.getDeliveryAddresesForSelectedProduct();
            this.getCustomerOrderDetails();
          } else if (response.status == 400 || response.status == 403) {
            this.orderError = {
              code: "HEADER"
            };
          } else {
            this.orderError = {
              code: "HEADER"
            };
          }
          this.showModalPopup("modal_popup_loading", false);
        })
        .catch(() => {
          this.showModalPopup("modal_popup_loading", false);
          this.orderError = {
            code: "HEADER"
          };
        });
    },
    getCustomerOrderDetails() {
      // this.showModalPopup("modal_popup_loading", true);
      if (this.order.status === 'Z'){
        this.beneficiaries = [];
        this.beneficiariesCount = 0;
        return;
      }
      this.beneficiaries = null;
      let searchParams =
        "?per_page=" + this.perPage + "&page=" + this.currentPage;
      if (this.search.length > 0) {
        searchParams += "&keyword=" + this.search;
      }
      httpServiceCustomer.customerService
        .getOrderDetails(
          this.orderId,
          this.customer.company_id,
          Cookies.get("token"),
          this.orderType,
          searchParams
        )
        .then(function (response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.beneficiaries = response.data;
            this.openDetails = true;
            this.orderError = null;
            this.beneficiariesCount = response.headers["x-up-count"];
            this.showModalPopup("modal_popup_loading", false);
          } else if (response.status == 400 || response.status == 403) {
            this.orderError = {
              code: "DETAILS"
            };
            this.showModalPopup("modal_popup_loading", false);
          } else {
            this.orderError = {
              code: "DETAILS"
            };
            this.showModalPopup("modal_popup_loading", false);
          }
          this.showModalPopup("modal_popup_loading", false);
        })
        .catch(() => {
          this.orderError = {
            code: "DETAILS"
          };
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    downloadOrderFile(documentType) {
      if (this.order.status === 'Z'){
        return;
      }
      if (documentType === "") {
        documentType = this.order.id.substring(0, 3);
      }
      if (documentType === "100" && !this.isInvoiceTypeProform) {
        documentType = "101";
      }
      if (
        documentType === "101" &&
        this.customer.electronic_invoice === false
      ) {
        this.showModalPopup("modal_popup_activateInvoices", true);
        return;
      }
      if (documentType === "102" && this.order.type == "NEW") {
        documentType = "106";
      }

      this.showModalPopup("modal_popup_loading", true);
      let link =
        this.customer.company_id +
        "/customer_order_documents/" +
        documentType +
        this.order.id.substring(documentType.length);
      httpServiceCustomer.customerService
        .getOrderDocumentFile(link, Cookies.get("token"), false)
        .then(function (response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            var blob = new Blob(
              [utils.base64ToArrayBuffer(response.data.content)],
              {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel"
              }
            );
            if (documentType === "103" ) {
              saveAs(blob, response.data.invoice + ".xlsx");
            } else if (documentType === "113" ) {
              saveAs(blob, response.data.invoice + ".csv");
            } else {
              saveAs(blob, response.data.invoice + ".pdf");
            }
            this.showModalPopup("modal_popup_loading", false);
            this.orderError = null;
          } else if (response.status == 400 || response.status == 403) {
            this.deliveryAddresses = null;
            this.orderError = {
              code: "DOWNLOAD"
            };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else if (response.status == 404) {
            this.orderError = {
              code: "DOWNLOAD"
            };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.orderError = {
              code: "DOWNLOAD"
            };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          this.orderError = {
            code: "DOWNLOAD"
          };
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    downloadExcelOrderFile() {
      httpServiceCustomer.customerService
        .getOrderModelFile(
          this.customer.company_id,
          this.orderId,
          this.orderType,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            var blob = new Blob(
              [utils.base64ToArrayBuffer(response.data.content)],
              {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel"
              }
            );
            saveAs(blob, response.data.name);
          }
        });
    },
    selectBeneficiaryMobile(index) {
      this.beneficiarySelectedMobile = this.beneficiaries[index];
      this.detailsMobile = !this.detailsMobile;
    },
    changeReverse() {
      this.reverse = !this.reverse;
    },
    selectColorStatus(status) {
      return utils.selectColorStatus(status);
    },
    getProductNameMethod(productId) {
      return utils.getCompanyProductName(productId, this.customer.products);
    },
    getDisplayDate(date) {
      var date = new Date(date);
      if (date.getFullYear() > 1900){
        return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format("DD/MM/YYYY");
      } else {
        return "N/A";
      }

    },
    getDeliveryAddresesForSelectedProduct() {
      var formData =
        "?brand=" +
        utils.getProductById(this.order.product, this.customer.products).brand;
      formData += "&type=customer";
      if (this.order.type === "PAPER") {
        formData += "&environment=paper";
      } else {
        formData += "&environment=card";
      }
      formData += "&per_page=" + 10000 + "&page=" + 1;

      httpServiceCompany.userService
        .getAddressPoints(
          this.customer.company_id,
          formData,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .catch(error => {
          this.deliveryAddresses = null;
        })
        .then(response => {
          if (response.status == 200) {
            this.deliveryAddresses = response.data;
          } else if (response.status == 400 || response.status == 403) {
            this.deliveryAddresses = null;
          }
        });
    },
    getAddressDisplayName(addressCode) {
      for (let index in this.deliveryAddresses) {
        if (this.deliveryAddresses[index].codcom === addressCode) {
          return (
            this.deliveryAddresses[index].codcom +
            " - " +
            this.deliveryAddresses[index].address.street_name +
            ", " +
            this.deliveryAddresses[index].address.locality
          );
        }
      }
      return addressCode;
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    selectOrderAction(order, action) {
      if (action === "cancel") {
        this.showModalPopup("modal_popup_confirmOrderDelete", true);
        this.orderForDelete = order;
      } else if (action === "awb") {
        this.orderForTrack = order;
        this.getAWBSForOrder(this.orderForTrack);
        this.showModalPopup("modal_popup_track", true);
      }
    },
    getAWBSForOrder(order) {
      this.showModalPopup("modal_popup_loading", true);
      this.orderAWBLoaded = false;
      this.trackHasStatus = false;

      httpServiceCustomer.customerService
        .getAWBForOrder(
          this.customer.company_id,
          order.id,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .catch(error => {
          if (error.response) {
            this.showModalPopup("modal_popup_loading", false);
          }
        })
        .then(response => {
          if (response.status == 200) {
            this.orderForTrack.awb = response.data;
            this.orderAWBLoaded = true;
          } else if (response.status == 400 || response.status == 403) {
            this.orderForTrack.awb = null;
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    getAWBStatus(awb) {
      this.showModalPopup("modal_popup_loading", true);

      httpServiceCustomer.customerService
        .getAWBStatus(
          this.customer.company_id,
          this.orderForTrack.id,
          awb,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .catch(error => {
          if (error.response) {
            this.showModalPopup("modal_popup_loading", false);
          }
        })
        .then(response => {
          if (response.status == 200) {
            this.statusAWB = response.data;
            this.trackHasStatus = true;
          } else if (response.status == 400 || response.status == 403) {
            this.statusAWB = null;
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    cancelOrder(order) {
      this.showModalPopup("modal_popup_confirmOrderDelete", false);
      this.showModalPopup("modal_popup_loading", true);
      httpServiceCustomer.customerService
        .cancelOrder(this.customer.company_id, order, Cookies.get("token"))
        .then(function (response) {
          return response;
        })
        .catch(() => {
          this.showModalPopup("modal_popup_loading", false);
          this.orderError = {
            code: "DELETE"
          };
        })
        .then(response => {
          if (response.status == 200) {
            this.orderError = null;
            this.deleteSuccess = true;
            this.getCustomerOrderHeader();
          } else if (response.status == 400 || response.status == 403) {
            this.orderError = {
              code: "DELETE"
            };
          } else {
            this.orderError = {
              code: "DELETE"
            };
          }
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
          this.showModalPopup("modal_popup_confirmOrderDelete", false);
          this.orderForDelete = null;
        });
    },
    selectAddressAWBMobile(index) {
      this.selectedAWB = this.orderForTrack.awb.addresses[index];
      this.detailsMobile = !this.detailsMobile;
    },
    onlyAlphaNumericCharacters(variable) {
      if (/^[a-zA-Z0-9]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g, "");
      }
    },
    updateInputCharacters() {
      this.search = this.onlyAlphaNumericCharacters(this.search);
      this.$forceUpdate();
    }
  },
  computed: {
    isInvoiceTypeProform: function () {
      let pr = this.order.id.substring(1, 3) === "00";
      let result = this.customer.products.filter(
        x => x.card === this.order.product || x.paper === this.order.product
      );
      return pr && result[0].payment_type === "Z";
    },
    orderAddresses: function () {
      let addresses = [];
      for (let index in this.beneficiaries) {
        if (!addresses.includes(this.beneficiaries[index].address)) {
          addresses.push(this.beneficiaries[index].address);
        }
      }
      return addresses;
    },
  },
  watch: {
    currentPage: function () {
      this.getCustomerOrderDetails();
    }
  },
  mounted() {
    this.getCustomerOrderHeader();
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
