<template>
  <div>
    <div class="orders-page">
      <div v-if="orderSelected === null">
        <div>
          <div class="left">
            <h1>{{ $t("ordersName") }}</h1>
            <p v-if="this.pendingOrders">
              {{
                $t("pendingOrdersMessage", {
                  noOrders: this.pendingOrders.length
                })
              }}
            </p>
          </div>
          <div
            v-if="
              (!flxUserLevel().hasAccess ||
                (flxUserLevel().hasAccess &&
                  this.customer.can_order_up) ||
                this.customer.role === 'FAC') &&
                this.customer.company_id !== 0
            "
            class="right"
          >
            <a  class="new-order-button cursor-pointer"
            @click="pushRoute('customer_new_order')"
            >
              {{ $t("newOrderName") }}
            </a>
          </div>
        </div>
        <br clear="all" />

        <div class="container-alb filter-mobile">
          <table
            cellpadding="10"
            cellspacing="0"
            width="95%"
            class="hover-table"
            align="center"
          >
            <tr>
              <td>
                <img src="@/assets/images/filter.svg" />
                <span class="mobile-show margin-left-5">{{
                  $t("invoicesFilterTitle")
                }}</span>
              </td>
              <td>
                {{ $t("invoicesFilterProduct") }}
                <select class="filtrare" @change="productSelected">
                  <option
                    v-for="(entry, index) in customer.products"
                    :key="index"
                    :value="entry.brand"
                    :selected="isSelectedProduct(entry.brand)"
                  >
                  {{ entry.name }}
                    <!-- {{
                      getProductNameMethod(
                        entry.card === 0
                          ? entry.paper
                          : entry.card
                      )
                    }} -->
                  </option>
                </select>
              </td>
              <td v-if="flxUserLevel().hasAccess">
                {{ $t("invoicesFilterPlatform") }}
                <select class="filtrare" @change="sourceSelected">
                  <option
                    v-for="(entry, index) in sources"
                    :key="index"
                    :value="entry.id"
                    :selected="filters.source === entry.id"
                  >
                    {{ entry.name }}
                  </option>
                </select>
              </td>
              <td>
                {{ $t("invoicesFilterPeriod") }}
                <date-range
                  class="invoices-filter"
                  @close="periodFilterChanged"
                  :time="this.filters.period"
                  :noRanges="false"
                  :single="false"
                />
              </td>
            </tr>
          </table>
        </div>

        <div class="container-alb moverflow">
          <div class="table-header-wrapper">
            <div class="left">
              <h3 class="table-title">{{ $t("pendingOrdersTitle") }}</h3>
            </div>
          </div>

          <orders-table-desktop
            @change="sortBy"
            @click="selectPendingOrderMobile"
            :companyOrders="pendingOrders"
            :pending="true"
            :company="this.customer"
            class="scroll-content-orders"
          />
          <div
            class="error-wrapper border-box"
            v-if="
              this.pendingOrders !== null && this.pendingOrdersCount === '0'
            "
          >
            <p>{{ $t("noOrdersMessage") }}</p>
          </div>
        </div>

        <div class="container-alb moverflow">
          <div class="table-header-wrapper">
            <div class="left">
              <h3 class="table-title">{{ $t("ordersHistoryTitle") }}</h3>
            </div>
          </div>

          <orders-table-desktop
            @change="sortBy"
            @click="selectPendingOrderMobile"
            :companyOrders="orders"
            :pending="false"
            :company="this.customer"
          />
          <div class="pagination-wrapper border-box">
            <b-pagination
              v-if="this.orders"
              v-model="currentPage"
              :total-rows="ordersCount"
              :per-page="perPage"
              aria-controls="orders-table"
              size="sm"
            ></b-pagination>
          </div>
          <div
            class="error-wrapper border-box"
            v-if="this.orders !== null && this.ordersCount === '0'"
          >
            <p>{{ $t("noOrdersMessage") }}</p>
          </div>
        </div>
      </div>
      <div v-else>
        <h3 class="return">
          <a href="#" @click="orderSelected = null">
            <font-awesome-icon icon="chevron-left" />
            <span>{{ $t("backButtonLabel") }}</span>
          </a>
        </h3>
        <OrderDetails
          :orderId="this.orderSelected.id"
          :orderType="this.orderSelected.type"
          :customer="this.customer"
        />
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_info', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p
          v-if="this.orderError"
          v-html="$t('orderError_' + this.orderError.code)"
        ></p>

        <p v-if="!this.orderError && this.orderForDelete" v-html="$t('orderDeleteSuccess')"></p>
        <p v-if="!this.orderError && this.orderForUrgent" v-html="$t('orderUrgentSuccess')"></p>
      </div>
    </div>
    <div id="modal_popup_confirmOrderDelete" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_confirmOrderDelete', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p v-html="$t('confirmOrderDelete')"></p>
        <div class="buttons">
          <a href="#" class="yesButton" @click="cancelOrder(orderForDelete)">
            {{ $t("notificationsChooseOptionYes") }}
          </a>
          <a
            href="#"
            class="noButton"
            @click="showModalPopup('modal_popup_confirmOrderDelete', false)"
            >{{ $t("notificationsChooseOptionNo") }}</a
          >
        </div>
      </div>
    </div>
    <div id="modal_popup_confirmOrderUrgent" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_confirmOrderUrgent', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p v-html="$t('confirmOrderUrgent')"></p>
        <div class="buttons">
          <a href="#" class="yesButton" @click="urgentOrder(orderForUrgent)">
            {{ $t("notificationsChooseOptionYes") }}
          </a>
          <a
            href="#"
            class="noButton"
            @click="showModalPopup('modal_popup_confirmOrderUrgent', false)"
            >{{ $t("notificationsChooseOptionNo") }}</a
          >
        </div>
      </div>
    </div>
    <div id="modal_popup_track" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_track', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <table
          cellpadding="10"
          cellspacing="0"
          width="95%"
          class="hover-table invoices-table text-left"
          align="center"
        >
          <thead>
            <tr>
              <td valign="middle">
                <strong>{{ $t("beneficiariesFilterDeliveryAddress") }}</strong>
              </td>
              <td valign="middle" class="priority-2">
                <strong>{{ $t("courierName") }}</strong>
              </td>
              <td valign="middle" class="priority-3">
                <strong>{{ $t("trackOrderButtonLabel") }}</strong>
              </td>
              <td valign="middle">
                <strong>{{ $t("beneficiariesTableSeeMore") }}</strong>
              </td>
            </tr>
          </thead>
          <tbody v-if="this.orderAWBLoaded">
            <template v-for="(address, index) in orderForTrack.awb.addresses">
              <tr
                :key="index"
                class="trhover parent"
                :class="index % 2 === 0 && 'gri-cell'"
              >
                <td valign="middle">
                  <a
                    @click="selectAddressAWBMobile(index)"
                    class="detailsOrderMobile priority-show-3 display-inline-block"
                  >
                    <font-awesome-icon
                      icon="minus-circle"
                      v-if="detailsMobile && address === selectedAWB"
                    />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                  <span>{{ address.locality + ", " + address.county }}</span>
                </td>
                <td valign="middle" class="priority-2">
                  <span>{{ address.awb.courier_name }}</span>
                </td>
                <td valign="middle" class="priority-3">
                  <span>{{ address.awb.awb_number }}</span>
                </td>
                <td valign="middle">
                  <a
                    v-if="!trackHasStatus"
                    class="action-button"
                    @click="getAWBStatus(address.awb)"
                  >
                    <span>{{ $t("details") }}</span>
                  </a>
                  <div v-else>
                    <span v-if="statusAWB.awb_message">{{
                      statusAWB.awb_message
                    }}</span>
                    <span v-else>{{ $t("statusAWBUnavailable") }}</span>
                  </div>
                </td>
              </tr>
              <tr
                :key="index + orderForTrack.awb.addresses.length"
                class="child"
                :class="index % 2 === 0 && 'gri-cell'"
                v-show="detailsMobile && address === selectedAWB"
              >
                <td colspan="12">
                  <p class="priority-show-2">
                    <strong>{{ $t("courierName") }} : &nbsp;&nbsp;</strong>
                    <span>{{ address.awb.courier_name }}</span>
                  </p>
                  <p class="priority-show-3">
                    <strong
                      >{{ $t("trackOrderButtonLabel") }} : &nbsp;&nbsp;</strong
                    >
                    <span>{{ address.awb.awb_number }}</span>
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import OrderDetails from "./OrderDetails.vue";
import OrdersTableDesktop from "./OrdersTableDesktop.vue";
import { BPagination } from "bootstrap-vue";
import DateRange from "@/components/DateRange.vue";
import moment from "moment";
import httpServiceCustomer from "@/http/http-customer.js";
import httpServiceUser from "@/http/http-user.js";
import utils from "@/plugins/utils.js";

export default {
  props: {
    customer: Object,
    msg: String
  },
  components: {
    OrderDetails,
    DateRange,
    OrdersTableDesktop,
    "b-pagination": BPagination
  },
  data() {
    return {
      customerBeneficiaries: null,
      orderSelected: null,
      orders: null,
      pendingOrders: null,
      ordersCount: 0,
      pendingOrdersCount: 0,
      sortField: "order_date",
      sortDirection: "desc",
      reverse: false,
      window: {
        width: 0,
        height: 0
      },
      sources: [
        {
          id: "",
          name: ""
        },
        {
          id: "UP",
          name: "UpRomania"
        },
        {
          id: "FLEX",
          name: "Multibeneficii"
        }
      ],
      filters: {
        product: "dejun",
        invoiceStatus: null,
        period: [moment().subtract(3, "month"), moment()],
        source: ""
      },
      detailsMobile: false,
      orderSelectedMobile: null,
      pendingOrderSelectedMobile: null,
      perPage: 10,
      currentPage: 1,
      orderError: null,
      orderForDelete: null,
      orderForUrgent: null,
      orderForTrack: null,
      orderAWBLoaded: false,
      deliveryAddresses: null,
      trackHasStatus: false,
      selectedAWB: null,
      statusAWB: null
    };
  },
  methods: {
    productSelected(event) {
      this.filters.product = event.target.value;
      this.getCustomerOrders("pending");
      this.getCustomerOrders();
    },
    sourceSelected(event) {
      this.filters.source = event.target.value;
      this.getCustomerOrders("pending");
      this.getCustomerOrders();
    },
    isSelectedProduct(product) {
      return product === this.filters.product;
    },
    sortBy(sortFieldChange, sortDirectionChange, pendingChange) {
      this.sortField = sortFieldChange;

      this.sortDirection = sortDirectionChange;
      this.getCustomerOrders(pendingChange ? "pending" : "");
    },
    getCustomerOrders(status = null) {
      this.orders = null;
      if (status === "pending") {
        this.pendingOrders = null;
      }
      var ordersData =
        "?type=" +
        "customer" +
        "&brand=" +
        this.filters.product +
        "&end_date=" +
        moment(this.filters.period[1]).format("YYYYMMDD") +
        "&start_date=" +
        moment(this.filters.period[0]).format("YYYYMMDD");
      if (this.filters.source.length > 0) {
        ordersData += "&source=" + this.filters.source;
      }
      if (status === "pending") {
        ordersData += "&status=L,E,P,N,PR,PRP,PV,R";
        ordersData += "&per_page=100" + "&page=1";
      } else {
        ordersData += "&status=A,S,V,Z,X";
        ordersData += "&per_page=" + this.perPage + "&page=" + this.currentPage;
      }
      ordersData += "&sort=" + this.sortDirection;

      if (this.sortDirection === "desc") {
        ordersData += "&desc=" + this.sortField;
      } else {
        ordersData += "&asc=" + this.sortField;
      }
      httpServiceCustomer.customerService
        .searchOrders(
          ordersData,
          this.customer.company_id,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .catch(error => {
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
          this.orderError = {
            code: error.response.status,
            description: error.response.statusText
          };
        })
        .then(response => {
          if (response.status == 200) {
            if (status === "pending") {
              this.pendingOrders = response.data;
              this.pendingOrdersCount = response.headers["x-up-count"]; //["Content-Length"];
            } else {
              this.orders = response.data;
              this.ordersCount = response.headers["x-up-count"]; //["Content-Length"];
            }
          } else if (response.status == 400 || response.status == 403) {
            this.orderError = {
              code: response.data,
              description: "Error fetching orders"
            };
          } else {
            this.orderError = { description: response.description };
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    selectPendingOrderMobile(order, action) {
      if (action === "cancel") {
        this.showModalPopup("modal_popup_confirmOrderDelete", true);
        this.orderForDelete = order;
      } else if (action === "urgent") {
        this.showModalPopup("modal_popup_confirmOrderUrgent", true);
        this.orderForUrgent = order;
      } else if (action === "details") {
        this.orderSelected = order;
      } else if (action === "awb") {
        this.orderForTrack = order;
        this.getAWBSForOrder(this.orderForTrack);
        this.showModalPopup("modal_popup_track", true);
      }
    },
    periodFilterChanged(value) {
      this.filters.period = value;
      this.getCustomerOrders();
    },
    getAWBSForOrder(order) {
      this.showModalPopup("modal_popup_loading", true);
      this.orderAWBLoaded = false;
      this.trackHasStatus = false;

      httpServiceCustomer.customerService
        .getAWBForOrder(
          this.customer.company_id,
          order.id,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .catch(error => {
          if (error.response) {
            this.showModalPopup("modal_popup_loading", false);
          }
        })
        .then(response => {
          if (response.status == 200) {
            this.orderForTrack.awb = response.data;
            this.orderAWBLoaded = true;
          } else if (response.status == 400 || response.status == 403) {
            this.orderForTrack.awb = null;
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    getAWBStatus(awb) {
      this.showModalPopup("modal_popup_loading", true);

      httpServiceCustomer.customerService
        .getAWBStatus(
          this.customer.company_id,
          this.orderForTrack.id,
          awb,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .catch(error => {
          if (error.response) {
            this.showModalPopup("modal_popup_loading", false);
          }
        })
        .then(response => {
          if (response.status == 200) {
            this.statusAWB = response.data;
            this.trackHasStatus = true;
          } else if (response.status == 400 || response.status == 403) {
            this.statusAWB = null;
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    getProductNameMethod(productId) {
      return utils.getCompanyProductName(productId, this.customer.products);
    },
    cancelOrder(order) {
      this.showModalPopup("modal_popup_confirmOrderDelete", false);
      this.showModalPopup("modal_popup_loading", true);
      httpServiceCustomer.customerService
        .cancelOrder(this.customer.company_id, order, Cookies.get("token"))
        .then(function(response) {
          return response;
        })
        .catch(() => {
          this.showModalPopup("modal_popup_loading", false);
        })
        .then(response => {
          if (response.status == 200) {
            this.getCustomerOrders();
            this.getCustomerOrders("pending");
          } else if (response.status == 400 || response.status == 403) {
            this.orderError = {
              code: "DELETE",
              description: "Error deleting order"
            };
          } else {
            this.orderError = {
              code: "DELETE",
              description: response.description
            };
          }
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
          this.showModalPopup("modal_popup_confirmOrderDelete", false);
          this.orderForDelete = null;
        });
    },
    urgentOrder(order) {
      this.showModalPopup("modal_popup_confirmOrderUrgent", false);
      this.showModalPopup("modal_popup_loading", true);
      this.orderError = null;
      httpServiceCustomer.customerService
        .urgentOrder(this.customer.company_id, order, Cookies.get("token"))
        .then(function(response) {
          return response;
        })
        .catch(error => {
          return error.response;
        })
        .then(response => {
          if (response.status == 200) {
            this.getCustomerOrders();
            this.getCustomerOrders("pending");
          } else if (response.status == 400 || response.status == 403) {
            this.orderError = {
              code: "URGENT",
              description: "Error sending order to production"
            };
          } else if (response.status == 406) {
            this.orderError = {
              code: "URGENT_HOUR",
              description: "Error sending order to production"
            };
          } else {
            this.orderError = {
              code: "URGENT",
              description: response.description
            };
          }
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
          this.showModalPopup("modal_popup_confirmOrderUrgent", false);
          this.orderForDelete = null;
        });
    },
    trackOrder() {
      this.trackHasStatus = true;
    },
    getDeliveryAddresesForSelectedProduct() {
      var formData =
        "?brand=" +
        utils.getProductById(this.orderForTrack.product, this.customer.products)
          .brand;
      if (this.orderForTrack.type === "PAPER") {
        formData += "&environment=paper";
      } else {
        formData += "&environment=card";
      }
      formData += "&type=customer";
      formData += "&per_page=" + 10000 + "&page=" + 1;

      this.showModalPopup("modal_popup_loading", true);

      httpServiceUser.userService
        .getAddressPoints(
          this.customer.company_id,
          formData,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .catch(error => {
          if (error.response) {
            this.showModalPopup("modal_popup_loading", false);
          }
        })
        .then(response => {
          if (response.status == 200) {
            this.deliveryAddresses = response.data;
          } else if (response.status == 400 || response.status == 403) {
            this.deliveryAddresses = null;
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    getAddressDisplayName(addressCode) {
      for (let index in this.deliveryAddresses) {
        if (this.deliveryAddresses[index].codcom === addressCode) {
          return (
            this.deliveryAddresses[index].codcom +
            " - " +
            this.deliveryAddresses[index].address.street_name +
            ", " +
            this.deliveryAddresses[index].address.locality
          );
        }
      }
      return addressCode;
    },
    selectAddressAWBMobile(index) {
      this.selectedAWB = this.orderForTrack.awb.addresses[index];
      this.detailsMobile = !this.detailsMobile;
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.token = Cookies.get("token");
    let orderId = utils.getUrlParam("order_id");
    if (orderId) {
      this.orderSelected = { id: orderId, type: "NEWCR" };
    }
    this.filters.product = this.customer.products[0].brand;
    this.getCustomerOrders();

    this.getCustomerOrders("pending");
  },
  watch: {
    currentPage: function() {
      this.getCustomerOrders();
    }
  }
};
</script>

<style scoped src="@/assets/styles/beneficiary.css"></style>
<style>
.invoices-filter .reportrange-text {
  height: 27px;
  font-size: 13px;
  padding: 3px 10px;
  border: none !important;
  color: #585858;
  background: #f1f1f1;
}
.scroll-content-orders {
  display: block;
  width: 100%;
  overflow-y: scroll;
  max-height: 200px;
}

</style>
